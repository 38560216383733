import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Home from 'components/Home';

const IndexPage = props => (
  <Layout>
    <Home
      page={props.data.contentfulHomePage}
      allShopifyProducts={props.data.allShopifyProduct}
    />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query {
    contentfulHomePage {
      heroSlides {
        ...SlideFields
      }
      stylistFavoritesTitle
      stylistFavoritesLink
      stylistFavoritesProducts {
        ...ContentfulProductFields
      }
      colorSemiPermanentImages {
        localFile {
          publicURL
        }
      }
      colorSemiPermanentImagesMobile {
        localFile {
          publicURL
        }
      }
      colorPermanentImages {
        localFile {
          publicURL
        }
      }
      colorPermanentImagesMobile {
        localFile {
          publicURL
        }
      }
      colorDemiPermanentImages {
        localFile {
          publicURL
        }
      }
      colorDemiPermanentImagesMobile {
        localFile {
          publicURL
        }
      }
      colorSimplyBlondeImages {
        localFile {
          publicURL
        }
      }
      colorSimplyBlondeImagesMobile {
        localFile {
          publicURL
        }
      }
      colorStudioStylistExpressImages {
        localFile {
          publicURL
        }
      }
      colorStudioStylistExpressImagesMobile {
        localFile {
          publicURL
        }
      }
      tutorialVideo {
        ...ContentfulVideoFields
      }
    }
    allShopifyProduct {
      nodes {
        ...ShopifyProductFields
      }
    }
  }
`;
