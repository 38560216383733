import React from 'react';
import get from 'lodash.get';
import { Link } from 'gatsby';

import { getProductUrl } from 'helpers/url';
import {
  Slider,
  SliderElms,
  SliderFavorite,
  SharedStyles,
  Container,
} from '@matchbox-mobile/kenra-storybook';

const { StSliderLinkOverlay } = SliderElms;

const {
  StSliderFavorite,
  StSliderFavoriteDesc,
  StSliderFavoriteImg,
  StSliderFavoriteText,
} = SliderFavorite;

const { StSectionMobileViewMore, StSectionTitle, StLink } = SharedStyles;

export default function StylistFavorites(props) {
  let { title, link, products, allShopifyProducts } = props;

  return (
    <StSectionMobileViewMore>
      <StSectionTitle>
        <Container>
          <h2>{title}</h2>
          <StLink mobileStyleLinkMore>
            <a href={link}>{`VIEW ALL (${products.length})`}</a>
          </StLink>
        </Container>
      </StSectionTitle>

      <Slider
        settings={{
          slidesPerView: 'auto',
        }}
      >
        {products.map((product, index) => {
          let handle = product.shopifyProduct.handle;
          let shopifyProduct = allShopifyProducts.find(
            node => node.handle === handle
          );

          let title = get(shopifyProduct, 'title', product.title);
          let subtitle = product.subtitle;
          let url = getProductUrl(handle);

          let productImage = get(
            shopifyProduct,
            'images[0].localFile.publicURL'
          );
          let hairImage = get(
            product,
            'colors[0].hairImage.localFile.stylist.resize.src'
          );

          return (
            <StSliderFavorite key={product.id + index}>
              <img src={hairImage || productImage} alt="" />
              <StSliderFavoriteDesc>
                {hairImage && (
                  <StSliderFavoriteImg>
                    <img src={productImage} alt="" />
                  </StSliderFavoriteImg>
                )}
                <StSliderFavoriteText>
                  <h3>{title}</h3>
                  {subtitle && <p>{subtitle}</p>}
                </StSliderFavoriteText>
              </StSliderFavoriteDesc>
              <StSliderLinkOverlay>
                <Link to={url}>See more</Link>
              </StSliderLinkOverlay>
            </StSliderFavorite>
          );
        })}
      </Slider>
    </StSectionMobileViewMore>
  );
}
