import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ImageWithDesc } from '@matchbox-mobile/kenra-storybook';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash.get';
import url from 'helpers/url';

export default function HomeQuiz() {
  const { contentfulQuiz } = useStaticQuery(graphql`
    query {
      contentfulQuiz {
        ...ContentfulQuizFields
      }
    }
  `);

  let { backgroundImage, title, description } = contentfulQuiz;

  return (
    <ImageWithDesc
      bg={get(backgroundImage, 'localFile.publicURL')}
      title={title}
      text={description && documentToReactComponents(description.json)}
      btnText="TAKE QUIZ"
      href={url.quiz}
    />
  );
}
