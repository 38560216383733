import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import get from 'lodash.get';
import { parseISO, format } from 'date-fns';

import url from 'helpers/url';
import { sortByCreatedAt } from 'helpers/sort';
import TOPICS from 'helpers/blogTopics';
import { getBlogPostUrl } from 'helpers/url';

import {
  SharedStyles,
  Container,
  Articles,
} from '@matchbox-mobile/kenra-storybook';

const { StSectionTitle, StLink } = SharedStyles;

export default function HomeArticles() {
  const {
    allContentfulBlogPost: { nodes },
  } = useStaticQuery(graphql`
    query {
      allContentfulBlogPost {
        nodes {
          ...ContentfulBlogPostFields
        }
      }
    }
  `);

  const articles = sortByCreatedAt(nodes).slice(0, 2);

  return (
    <section>
      <StSectionTitle>
        <Container>
          <h2>Latest articles</h2>
          <StLink mobileHide>
            <a href={url.blog}>VIEW ALL ({nodes.length})</a>
          </StLink>
        </Container>
      </StSectionTitle>
      <Articles
        items={articles.map(item => ({
          bg: get(item, 'image.localFile.publicURL'),
          type: item.topic,
          typeColor: get(TOPICS, `${item.topic}.color`),
          title: item.title,
          subtitle: `BY ${item.author}`,
          date: `posted ${format(parseISO(item.createdAt), 'd.MM.yyyy')}`,
          linkText: 'READ POST',
          linkHref: getBlogPostUrl(item.contentful_id),
        }))}
      />
    </section>
  );
}
