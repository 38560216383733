import React from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import url from 'helpers/url';
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';

import {
  Container,
  VideoWithDesc,
  SharedStyles,
} from '@matchbox-mobile/kenra-storybook';

const { StSectionTitle } = SharedStyles;

export default function HomeVideo({ tutorialVideo }) {
  let { youtubeLink, homeDescription } = tutorialVideo;
  let youTubeId = getYouTubeId(youtubeLink);

  return (
    <section>
      <StSectionTitle>
        <Container>
          <h2>Tutorial Videos</h2>
        </Container>
      </StSectionTitle>
      <VideoWithDesc
        text={homeDescription && documentToHtmlString(homeDescription.json)}
        linkMoreText="VIEW ALL VIDEOS"
        linkMoreHref={url.videos}
        bgImg={getPreviewUrl(youTubeId)}
        videoType="youtube"
        autoPlay
        videoOptions={[{ src: getEmbedUrl(youTubeId) }]}
      />
    </section>
  );
}
