import React from 'react';

import { Spacing } from '@matchbox-mobile/kenra-storybook';

import HeroSlider from 'components/HeroSlider';
import StylistFavorites from './StylistFavorites';
import HomeQuiz from './HomeQuiz';
import HomeColor from './HomeColor';
import HomeVideo from './HomeVideo';
import HomeArticles from './HomeArticles';

class Home extends React.Component {
  render() {
    let { page, allShopifyProducts } = this.props;

    return (
      <>
        <Spacing removeSpaceTop>
          <HeroSlider slides={page.heroSlides} />
        </Spacing>

        <Spacing>
          <StylistFavorites
            title={page.stylistFavoritesTitle}
            link={page.stylistFavoritesLink}
            products={page.stylistFavoritesProducts}
            allShopifyProducts={allShopifyProducts.nodes}
          />
        </Spacing>

        <Spacing>
          <HomeColor
            colorSemiPermanentImages={page.colorSemiPermanentImages}
            colorSemiPermanentImagesMobile={page.colorSemiPermanentImagesMobile}
            colorPermanentImages={page.colorPermanentImages}
            colorPermanentImagesMobile={page.colorPermanentImagesMobile}
            colorDemiPermanentImages={page.colorDemiPermanentImages}
            colorDemiPermanentImagesMobile={page.colorDemiPermanentImagesMobile}
            colorSimplyBlondeImages={page.colorSimplyBlondeImages}
            colorSimplyBlondeImagesMobile={page.colorSimplyBlondeImagesMobile}
            colorStudioStylistExpressImages={
              page.colorStudioStylistExpressImages
            }
            colorStudioStylistExpressImagesMobile={
              page.colorStudioStylistExpressImagesMobile
            }
          />
        </Spacing>

        <Spacing>
          <HomeVideo tutorialVideo={page.tutorialVideo} />
        </Spacing>

        <Spacing>
          <HomeQuiz />
        </Spacing>

        <Spacing>
          <HomeArticles />
        </Spacing>
      </>
    );
  }
}

export default Home;
