import React from 'react';
import get from 'lodash.get';

import {
  SliderColor,
  SharedStyles,
  Container,
  Tabs,
} from '@matchbox-mobile/kenra-storybook';

const GROUPS = [
  {
    text: 'Semi-Permanent',
    images: 'colorSemiPermanentImages',
  },
  {
    text: 'Permanent',
    images: 'colorSemiPermanentImages',
  },
  {
    text: 'Demi-Permanent',
    images: 'colorDemiPermanentImages',
  },
  {
    text: 'Simply Blonde',
    images: 'colorSimplyBlondeImages',
  },
  {
    text: 'Studio Stylist Express',
    images: 'colorStudioStylistExpressImages',
  },
];

const { StSectionMobileViewMore, StSectionTitle, StLink } = SharedStyles;

function getImages(desktopImages, mobileImages) {
  return desktopImages.map((desktopImage, index) => {
    let desktopImageURL = get(desktopImage, 'localFile.publicURL');
    let mobileImageURL = get(mobileImages[index], 'localFile.publicURL');
    return {
      imgContent: {
        desktop: desktopImageURL,
        mobile: mobileImageURL || desktopImageURL,
      },
      imgThumbnail: desktopImageURL,
    };
  });
}

function hasImages(desktopImages) {
  if (!Array.isArray(desktopImages) || desktopImages.length === 0) return false;
  return true;
}

export default function HomeColor(props) {
  return (
    <StSectionMobileViewMore>
      <StSectionTitle>
        <Container>
          <h2>DISCOVER KENRA COLOR</h2>
          <StLink mobileStyleLinkMore>
            <a href="/">VIEW ALL</a>
          </StLink>
        </Container>
      </StSectionTitle>
      <Tabs
        useTabs
        items={GROUPS.map(group => {
          if (!hasImages(get(props, group.images))) return null;
          return {
            text: group.text,
          };
        })
          .filter(Boolean)
          .map((g, index) => ({ ...g, isActive: index === 0 }))}
      >
        {GROUPS.map((group, index) => {
          if (!hasImages(get(props, group.images))) return null;

          return (
            <div key={`group-${index}`}>
              <SliderColor
                items={getImages(
                  get(props, group.images),
                  get(props, `${group.images}Mobile`)
                )}
              />
            </div>
          );
        }).filter(Boolean)}
      </Tabs>

      {/* <Tabs
        useTabs
        items={products.map((product, index) => ({
          text: getColorCategory(product.tags),
          isActive: index === 0,
        }))}
      >
        {products.map((product, index) => {
          let { colors, id } = product;

          return (
            <div key={`${id}-${index}`}>
              <SliderColor
                items={colors.map(color => ({
                  bgColor: color.color,
                  imgContent: {
                    desktop: get(color, 'homeSliderImage.localFile.publicURL'),
                    mobile: get(color, 'homeSliderImage.localFile.publicURL'),
                  },
                  imgThumbnail: get(
                    color,
                    'hairImage.localFile.color_preview.resize.src'
                  ),
                }))}
              />
            </div>
          );
        })}
      </Tabs> */}
    </StSectionMobileViewMore>
  );
}
