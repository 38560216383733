import React from 'react';
import Img from 'gatsby-image';
import get from 'lodash.get';

import {
  Slider,
  SliderHero,
  SharedStyles,
  Container,
} from '@matchbox-mobile/kenra-storybook';

const {
  StSliderHeroItem,
  StSliderHeroImg,
  StSliderHeroItemDesc,
  StSliderHeroItemDescFirst,
  StSliderHeroItemDescSecond,
} = SliderHero;

const { StLink } = SharedStyles;

export default function HeroSlider({ slides }) {
  return (
    <Slider>
      {slides.map((slide, index) => (
        <StSliderHeroItem key={`${slide.id}_${index}`}>
          <StSliderHeroImg>
            <a href={slide.slideLink}>
              {slide.image && (
                <Img fluid={get(slide.image, 'localFile.big.fluid')} />
              )}
            </a>
          </StSliderHeroImg>
          <StSliderHeroItemDesc>
            <Container>
              <StSliderHeroItemDescFirst>
                <h2>{slide.slideText}</h2>
                <StLink>
                  <a href={slide.slideLink}>{slide.slideLinkText}</a>
                </StLink>
              </StSliderHeroItemDescFirst>
              <StSliderHeroItemDescSecond>
                {slide.smallImage && (
                  <img
                    alt=""
                    src={get(slide.smallImage, 'localFile.publicURL')}
                  />
                )}
              </StSliderHeroItemDescSecond>
            </Container>
          </StSliderHeroItemDesc>
        </StSliderHeroItem>
      ))}
    </Slider>
  );
}
